import { useMemo } from 'react'
import moment from 'moment'
import Icon from '../../components/Icon'

import './Entry.scss'

function Entry({ envelopes, accounts, entry, background, alternateBackground, onAddCategory }) {
  const dateFormatted = useMemo(() => moment(entry.date).format('MMM D '), [entry.date])
  const amountWidth = 96
  const dateWidth = 78

  const categorySelect = entry.type === 'transaction' ? (
    <select
      className="absolute full-width full-height"
      style={{ opacity: 0, top: 0, left: 0 }}
      onChange={e => onAddCategory({ transactionId: entry.id, envelopeId: e.target.value })}
      value={entry.envelopeId}
    >
      <option></option>
      {Object.entries(envelopes).map(([id, envelope]) => (
        <option key={id} value={id}>{envelope.name}</option>
      ))}
    </select>
  ) : null

  return (
    <div style={{ background }}>
      <div
        className="flex justify-content-between align-items-center p-4 relative"
        style={{
          background: alternateBackground ? 'rgba(0,0,0,.1)' : undefined,
          height: 70,
          opacity: entry.envelopeId === 'ignore' ? .3 : 1
        }}
      >
        {!entry.envelopeId ? categorySelect : null}
        <div style={{ width: dateWidth, textAlign: 'left' }}>
          <div>{dateFormatted}</div>
        </div>
        <div style={{ width: `calc(100% - ${amountWidth + dateWidth}px)` }}>
          {entry.envelopeId ? (
            <div>
              {envelopes[entry.envelopeId].name}
              <div className="inline-block relative">
                <Icon type="pencil" className="category-edit-icon" />
                {categorySelect}
              </div>
            </div>
          ) : (
            entry.type === 'transfer' ? (
              <div>Transfer</div>
            ) : null
          )}
          <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
            <small>
              {entry.type === 'transaction'
                ? entry.name
                : `${ envelopes[entry.fromEnvelopeId]?.name } > ${ envelopes[entry.toEnvelopeId]?.name }`}
            </small>
          </div>
        </div>
        <div style={{ width: amountWidth, textAlign: 'right' }}>
          <div>
            {entry.amount < 0 ? '+ ' : null}
            {(Math.round(Math.abs(+entry.amount) * 100) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
          </div>
          {entry.type === 'transaction' ? (
            <div style={{color: '#444'}}>
              <small>{accounts[entry.accountId]?.mask}</small>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

export default Entry

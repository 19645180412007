let WsServer

export const connect = (port) => {
  WsServer = new WebSocket(`wss://${window.location.hostname}:${port}`)

  // Reconnect to server if pings are not received
  let reconnectInterval
  const restartReconnectInterval = () => {
    clearInterval(reconnectInterval)

    reconnectInterval = setInterval(() => {
      // Reconnect
      clearInterval(reconnectInterval)

      WsServer.onclose = null // Don't trigger WsServer.onclose()
      WsServer.close()
      WsServer = undefined

      connect(port)
    }, 5000)
  }
  restartReconnectInterval()

  WsServer.onopen = () => onOpenListeners.forEach(onOpenListener => onOpenListener())

  WsServer.onmessage = rawMessage => {
    let message
    try {
      message = JSON.parse(rawMessage.data)

      if (typeof message !== "object") return
    } catch (e) {
      return
    }

    if (message.method === "ping") restartReconnectInterval()
    else onMessageListeners.forEach(onMessageListener => onMessageListener(message))
  }

  // Reconnect to server on close
  WsServer.onclose = () => {
    clearInterval(reconnectInterval)
    WsServer = undefined

    setTimeout(() => connect(port), 1000)
  }
}

// onOpen
let onOpenListeners = []
export const addOnOpenListener = callback => {
  onOpenListeners.push(callback)

  // Trigger callback if connection is already open
  WsServer?.readyState === WebSocket.OPEN && callback()
}
export const removeOnOpenListener = callback => onOpenListeners = onOpenListeners.filter(cb => callback !== cb)

// onMessage
let onMessageListeners = []
export const addOnMessageListener = callback => onMessageListeners.push(callback)
export const removeOnMessageListener = callback => onMessageListeners = onMessageListeners.filter(cb => callback !== cb)

// sendMessage
export const sendMessage = message => {
  if (WsServer?.readyState === WebSocket.OPEN)
    WsServer.send(JSON.stringify(message))
  else
    throw new Error() // TODO: test and handle sendMessage errors?
}
import React, { useState, useRef, useMemo, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import moment from "moment"
import classNames from "classnames"

import AccountSelect from "./AccountSelect"
import { getAccount, getDefaultAccountName } from "../store"
import * as WsServer from "../services/WsServer"
import { sanitizeMoneyInput } from "../helpers"

const Adjustments = ({getAccount, defaultAccountName, transaction, disabled}) => {
  const today = useMemo(() => moment().startOf("day"), [])

  const forDates = useMemo(() => {
    if (!transaction.repeats) return null

    // Get all repeating dates
    let forDates = []
    const maxDate = transaction.repeats.endDate ? moment(transaction.repeats.endDate) : moment().add(3, "year")

    for (let i = moment(transaction.date); i.isSameOrBefore(maxDate); i.add(transaction.repeats.interval, transaction.repeats.frequency))
      forDates.push(i.format("YYYY-MM-DD"))

    // Filter out past dates (only show dates from the previous week onwards, and a date from the previous cycle)
    const weekAgo = moment(today).subtract(1, "week")
    const previousCycleDate = forDates.filter(date => today.isAfter(date)).pop()

    forDates = [...forDates.filter(date => weekAgo.isBefore(date))]
    if (previousCycleDate && !forDates.includes(previousCycleDate))
      forDates.unshift(previousCycleDate)

    return forDates
  }, [transaction.repeats, transaction.date, today])

  const selectableForDates = useMemo(() => {
    if (!forDates) return null

    const alreadyAdjustedForDates = Object.keys(transaction.adjustments)

    return forDates.filter(forDate => !alreadyAdjustedForDates.includes(forDate))
  }, [forDates, transaction.adjustments])

  const formDefaultValues = useCallback(forDate => {
    if (forDate === undefined) {
      // Attempt to select upcoming date that's not
      forDate = ""

      if (forDates !== null) {
        const currentOrFutureNotAdjustedForDates = selectableForDates.filter(date => today.isSameOrBefore(date))

        if (currentOrFutureNotAdjustedForDates.length)
          forDate = currentOrFutureNotAdjustedForDates[0]
        else if (selectableForDates.length)
          forDate = selectableForDates[0]
      }
    }

    const adjustment = forDate && transaction.adjustments[forDate]
    const adjustedTransaction = adjustment || transaction

    return {
      forDate,
      amount: adjustedTransaction.amount.toString(),
      date: adjustment?.date || forDate,
      fromAccountId: adjustedTransaction.fromAccountId?.toString() || "",
      toAccountId: adjustedTransaction.toAccountId?.toString() || ""
    }
  }, [selectableForDates, transaction, forDates, today])

  const [forDate, setForDate] = useState(formDefaultValues().forDate)
  const [amount, setAmount] = useState(formDefaultValues(forDate).amount)
  const [date, setDate] = useState(formDefaultValues(forDate).date)
  const [fromAccountId, setFromAccountId] = useState(formDefaultValues(forDate).fromAccountId)
  const [toAccountId, setToAccountId] = useState(formDefaultValues(forDate).toAccountId)
  const [formForDate, setFormForDate] = useState(null)
  const [confirmDeleteForDate, setConfirmDeleteForDate] = useState(null)
  const [confirmDeleteTimeout, setConfirmDeleteTimeout] = useState(null)

  const forDateElem = useRef(null)
  const adjustAmountElem = useRef(null)
  const previousForDate = useRef(forDate)

  const amountTitle = transaction.type === "income" ? "Deposit" : (transaction.type === "expense" ? "Withdraw" : "Transfer")

  const adjustmentsEntries = useMemo(() => (
    Object.entries(transaction.adjustments).sort((a, b) => a[0] > b[0] ? 1 : -1)
  ), [transaction.adjustments])

  const areInputsValid = useMemo(() => (
    amount &&
    moment(date).isValid() &&
    (transaction.type !== "transfer" || fromAccountId !== toAccountId) &&
    ( // At least one input should be different from transaction data
      amount !== transaction.amount.toString() ||
      date !== forDate ||
      fromAccountId !== (transaction.fromAccountId?.toString() || "") ||
      toAccountId !== (transaction.toAccountId?.toString() || "")
    )
  ), [amount, date, transaction, fromAccountId, toAccountId, forDate])

  const dateOptions = useMemo(() => {
    if (!transaction.repeats) return null

    const dateOptions = []

    for (let forDate of forDates) {
      dateOptions.push(
        <option key={forDate} value={forDate} disabled={!selectableForDates.includes(forDate)}>
          {moment(forDate).format("M/D/YY")}
        </option>
      )
    }

    return dateOptions
  }, [transaction.repeats, forDates, selectableForDates])

  const resetForm = useCallback(forDate => {
    const values = formDefaultValues(forDate)

    setForDate(values.forDate)
    setAmount(values.amount)
    setDate(values.date)
    setFromAccountId(values.fromAccountId)
    setToAccountId(values.toAccountId)
  }, [formDefaultValues])

  // Focus on input when form opens up
  useEffect(() => {
    if (formForDate === "new") forDateElem.current?.focus()
    else if (formForDate) adjustAmountElem.current?.focus()
  }, [formForDate])

  // Make sure fromAccountId and toAccountId are valid in-case accounts updates from backend
  useEffect(() => {
    if (fromAccountId && fromAccountId !== "default" && !getAccount(fromAccountId))
      setFromAccountId(formDefaultValues(forDate).fromAccountId)

    if (toAccountId && toAccountId !== "default" && !getAccount(toAccountId))
      setToAccountId(formDefaultValues(forDate).toAccountId)
  }, [fromAccountId, toAccountId, forDate, formDefaultValues, getAccount])

  // Make sure forDate is valid in-case adjustments updates from backend
  useEffect(() => {
    if (formForDate === "new" && !selectableForDates.includes(forDate))
      setForDate(formDefaultValues().forDate)
  }, [formForDate, selectableForDates, forDate, formDefaultValues])

  // If date is same as forDate, then update date to be same as forDate when user changes forDate
  useEffect(() => {
    if (forDate !== previousForDate.current) {
      if (date === previousForDate.current)
        setDate(forDate)

      previousForDate.current = forDate
    }
  }, [date, forDate])

  // Close editing form if date has been deleted from backend
  useEffect(() => {
    if (formForDate && formForDate !== "new" && !Object.keys(transaction.adjustments).includes(formForDate))
      setFormForDate(null)
  }, [formForDate, transaction.adjustments])

  // Clear old confirmDeleteTimeout when new confirmDeleteTimeout is set, and also when component closes
  useEffect(() => () => confirmDeleteTimeout && clearTimeout(confirmDeleteTimeout), [confirmDeleteTimeout])

  const renderInputs = adjustmentForDate => (
    <div className="form">
      <div>
        <div className="form-group">
          <label htmlFor="adjustAmount">{amountTitle}:</label>
          $<input id="adjustAmount" className="small" type="text" value={amount} onChange={e => setAmount(sanitizeMoneyInput(e.target.value))} ref={adjustAmountElem} />
        </div>
        <div className="form-group">
          <label htmlFor="adjustDate">On:</label>
          <input id="adjustDate" type="date" value={date} onChange={e => setDate(e.target.value)} />
        </div>
        {transaction.type !== "transfer" ? (
          <AccountSelect
            id={transaction.type === "income" ? "adjustToAccountId" : "adjustFromAccountId"}
            title={transaction.type === "income" ? "To" : "From"}
            value={transaction.type === "income" ? toAccountId : fromAccountId}
            onChange={e => transaction.type === "income" ? setToAccountId(e.target.value) : setFromAccountId(e.target.value)}
          />
        ) : null}
      </div>
      {transaction.type === "transfer" ? (
        <div>
          <AccountSelect id={"adjustFromAccountId"} title="From" value={fromAccountId} onChange={e => setFromAccountId(e.target.value)} />
          <AccountSelect id={"adjustToAccountId"} title="To" value={toAccountId} onChange={e => setToAccountId(e.target.value)} />
        </div>
      ) : null}
      <div className="form-group">
        {adjustmentForDate ? (
          <button
            type="button"
            className={confirmDeleteForDate !== adjustmentForDate ? "secondary" : "danger"}
            onClick={() => {
              if (confirmDeleteForDate !== adjustmentForDate) {
                setConfirmDeleteForDate(adjustmentForDate)

                setConfirmDeleteTimeout(setTimeout(() => setConfirmDeleteForDate(null), 2000))
              }
              else {
                setFormForDate(null)

                WsServer.sendMessage({method: "removeAdjustment", transactionId: transaction.id, forDate: adjustmentForDate})
              }
            }}
          >
            Delete
          </button>
        ) : (
          <button type="button" className="secondary" onClick={() => setFormForDate(null)}>Cancel</button>
        )}
        <button type="submit" disabled={!areInputsValid}>{adjustmentForDate ? "Update" : "Add"}</button>
      </div>
    </div>
  )

  if (!dateOptions || !dateOptions.length) return null // Don't show anything is user has no options to make adjustments

  // TODO: when adjustment form is open, esc should close the adjustment form, and not the modal
  return (
    <form
      className="mt-8"
      onSubmit={e => {
        e.preventDefault()

        if (!areInputsValid) return

        WsServer.sendMessage({
          method: "updateAdjustment",
          transactionId: transaction.id,
          forDate: moment(forDate).format("YYYY-MM-DD"),
          adjustment: {
            amount: +amount,
            date: moment(date).format("YYYY-MM-DD"),
            fromAccountId: fromAccountId === "default" ? fromAccountId : (fromAccountId ? +fromAccountId : null),
            toAccountId: toAccountId === "default" ? toAccountId : (toAccountId ? +toAccountId : null)
          }
        })

        setFormForDate(null)
      }}
    >
      {formForDate || adjustmentsEntries.length ? (<>
        <div className="table-overflow">
          <table className="striped">
            <thead>
            <tr>
              <th>For</th>
              <th colSpan={2}>Adjust</th>
            </tr>
            </thead>
            <tbody>
            {adjustmentsEntries.map(([adjustmentForDate, adjustment]) => (
              <tr
                key={adjustmentForDate}
                className={classNames({
                  pointer: !formForDate,
                  muted: formForDate !== adjustmentForDate && today.isAfter(adjustmentForDate) && today.isAfter(adjustment.date)
                })}
                onClick={() => {
                  if (!formForDate && !disabled) {
                    resetForm(adjustmentForDate)
                    setFormForDate(adjustmentForDate)
                  }
                }}
              >
                <td>{moment(adjustmentForDate).format("M/D/YY")}</td>
                {formForDate === adjustmentForDate ? (<>
                  <td className="full-width">{renderInputs(adjustmentForDate)}</td>
                  <td>
                    <div className="close" onClick={() => setFormForDate(null)}>&times;</div>
                  </td>
                </>) : (<>
                  <td className="full-width" colSpan={2}>
                    {amountTitle}
                    <> </>
                    <span className={adjustment.amount !== transaction.amount ? "weight-bold" : null}>${adjustment.amount}</span>
                    <> on </>
                    <span className={adjustment.date !== adjustmentForDate ? "weight-bold" : null}>{moment(adjustment.date).format("M/D/YY")}</span>
                    {adjustment.fromAccountId ? (<>
                      <> from </>
                      <span className={adjustment.fromAccountId !== transaction.fromAccountId ? "weight-bold" : null}>
                        {adjustment.fromAccountId === "default" ? defaultAccountName : (getAccount(adjustment.fromAccountId)?.name || null)}
                      </span>
                    </>) : null}
                    {adjustment.toAccountId ? (<>
                      <> to </>
                      <span className={adjustment.toAccountId !== transaction.toAccountId ? "weight-bold" : null}>
                        {adjustment.toAccountId === "default" ? defaultAccountName : (getAccount(adjustment.toAccountId)?.name || null)}
                      </span>
                    </>) : null}
                  </td>
                </>)}
              </tr>
            ))}
            {formForDate === "new" ? (
              <tr>
                <td>
                  <select
                    className="small"
                    value={forDate}
                    onChange={e => setForDate(e.target.value)}
                    ref={forDateElem}
                  >
                    {dateOptions}
                  </select>
                </td>
                <td className="full-width">{renderInputs()}</td>
                <td>
                  <div className="close" onClick={() => setFormForDate(null)}>&times;</div>
                </td>
              </tr>
            ) : null}
            </tbody>
          </table>
        </div>
      </>) : null}

      {!formForDate && selectableForDates.length && !disabled ? (
        <button
          type="button"
          className={adjustmentsEntries.length ? "mt-2" : null}
          onClick={() => {
            const forDate = formDefaultValues().forDate

            resetForm(forDate)
            setFormForDate("new")
          }}
          disabled={!!formForDate}
        >
          Add Adjustment
        </button>
      ) : null}
    </form>
  )
}

const mapStateToProps = state => ({
  getAccount: accountId => getAccount(state, accountId),
  defaultAccountName: getDefaultAccountName(state)
})

export default connect(mapStateToProps)(Adjustments)

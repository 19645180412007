import { useEffect, useMemo, useState, useRef } from 'react';

import Entry from '../components/Entry'
import Icon from '../../components/Icon'
import Transfer from '../components/Transfer'
import '../components/SwipeDelete/css/main.scss'

function Entries({ entries, accounts, envelopes, background, onAddCategory, onTransfer }) {
  const scrolledToBottom = useRef(false)
  const [isTransfersOpen, setIsTransfersOpen] = useState(false)

  // Scroll to bottom
  useEffect(() => {
    if (entries?.length && !scrolledToBottom.current) {
      setTimeout(() => window.scroll(0, 100000000), 100)
      setTimeout(() => window.scroll(0, 100000000), 200)
      setTimeout(() => window.scroll(0, 100000000), 300)
      setTimeout(() => window.scroll(0, 100000000), 400)

      scrolledToBottom.current = true
    }
  }, [entries])

  const categorizedEntries = useMemo(() => (entries || []).filter(e => e.type === 'transfer' || e.envelopeId), [entries])
  const pendingEntries = useMemo(() => (entries || []).filter(e => e.type === 'transaction' && !e.envelopeId), [entries])
  const displayBalance = useMemo(() => {
    const envelopesBalance = Object.entries(envelopes)
      .filter(([id]) => id !== 'ignore')
      .map(([id, e]) => e.balance)
      .reduce((a, b) => a + b, 0)
    const pendingReviewBalance = pendingEntries.map(e => e.amount).reduce((a, b) => a + b, 0)

    return (envelopesBalance - pendingReviewBalance).toLocaleString('en-US', {style: 'currency', currency: 'USD'})
  }, [envelopes, pendingEntries])

  return (
    <div>
      {categorizedEntries.map((entry, i) => (
        <Entry
          key={entry.type === 'transaction' ? entry.id : `transfer-${i}`}
          envelopes={envelopes}
          accounts={accounts}
          entry={entry}
          background={background}
          alternateBackground={i % 2}
          onAddCategory={onAddCategory}
        />
      ))}

      {pendingEntries.length ? (
        <h3 className="px-4 pt-8 mb-0">Pending Review</h3>
      ) : null}
      {pendingEntries.map((entry, i) => (
        <Entry
          key={entry.type === 'transaction' ? entry.id : `transfer-${i}`}
          envelopes={envelopes}
          accounts={accounts}
          entry={entry}
          background={background}
          alternateBackground={i % 2}
          onAddCategory={onAddCategory}
        />
      ))}

      <div className="flex justify-content-between pt-8 pb-8 px-4">
        <div>
          <h3 className="mb-0">Balance</h3>
          <div>
            {displayBalance}
          </div>
        </div>
        <div className="align-self-center">
          <Icon type="transfer" onClick={() => setIsTransfersOpen(true)} />
        </div>
      </div>

      {isTransfersOpen ? (
        <div
          style={{ left: 0, top: 0, background: 'rgba(0, 0, 0, .7)', zIndex: 99999999 }}
          className="fixed full-height full-width flex align-items-center"
        >
          <div
            style={{
              padding: 20,
              margin: 20,
              width: 'calc(100% - 40px)',
              background: 'white',
            }}
          >
            <Transfer
              envelopes={envelopes}
              onClose={() => setIsTransfersOpen(false)}
              onSubmit={(t) => {
                onTransfer(t)
                setIsTransfersOpen(false)
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default Entries

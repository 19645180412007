import React, { useState, useMemo, useEffect, Fragment } from "react"
import { connect } from "react-redux"
import moment from "moment"

import { getAccount, getSortedAccounts } from "../store"
import Icon from "../components/Icon"
import Modal from "../components/Modal"
import BalancesAndPendingTransactions from "../components/BalancesAndPendingTransactions"
import TransactionForm from "../components/TransactionForm"
import * as WsServer from "../services/WsServer"

const Dashboard = ({settings, accounts, getAccount, transactions}) => {
  const [isBalancesAndPendingsOpen, setIsBalancesAndPendingsOpen] = useState(false)
  const [monthsOpened, setMonthsOpened] = useState(["pending", moment().format("YYYY-MM")])
  const [editEntry, setEditEntry] = useState(null)
  const [dailyTransactionsOpened, setDailyTransactionsOpened] = useState([])
  const [accountIds, setAccountIds] = useState(
    localStorage.getItem("accountIds") ?
      JSON.parse(localStorage.getItem("accountIds")).filter(accountId => getAccount(accountId)) :
      [...accounts.map(account => account.id.toString())]
  )

  const filteredTransactions = useMemo(() => (
    transactions.filter(transaction => {
      const fromAccountId = getAccount(transaction.fromAccountId)?.id?.toString() || null
      const toAccountId = getAccount(transaction.toAccountId)?.id?.toString() || null

      return accountIds.includes(fromAccountId) || accountIds.includes(toAccountId)
    })
  ), [transactions, getAccount, accountIds])
  const today = useMemo(() => moment().startOf("day"), [])
  const startDate = useMemo(() => moment(today).subtract(6, "days"), [today]) // NOTE: should match willPostOn
  const endDate = useMemo(() => moment(today).add(3, "years").endOf("month"), [today])

  const [transactionEntriesGrouped, recentTransactionEntries] = useMemo(() => {
    const transactionsEntries = []
    const transactionEntriesGrouped = {}
    const recentTransactionEntries = []

    let balances = {}
    accountIds.forEach(accountId => balances[accountId] = getAccount(accountId).currentBalance)

    filteredTransactions.forEach(transaction => {
      const repeatEndDate = moment(transaction.repeats?.endDate ? transaction.repeats.endDate : (transaction.repeats ? endDate : transaction.date))

      // Repeat
      for (
        const date = moment(transaction.date);
        date.isSameOrBefore(repeatEndDate);
        date.add(transaction.repeats?.interval || 1, transaction.repeats?.frequency || "days")
      ) {
        const adjustment = transaction.adjustments[date.format("YYYY-MM-DD")]
        const adjustedDate = moment(adjustment?.date || date)
        const adjustedTransaction = adjustment || transaction

        // Filter
        if (adjustedDate.isBefore(startDate) || adjustedDate.isAfter(endDate)) continue

        let hasPosted, willPostOn
        if (typeof transaction.postedStates[date.format("YYYY-MM-DD")] === "boolean") {
          hasPosted = transaction.postedStates[date.format("YYYY-MM-DD")]

          //
          if (!hasPosted) willPostOn = moment(adjustedDate).add(1, "week") // NOTE: should match startDate
        }
        else {
          hasPosted = today.isAfter(adjustedDate)

          if (!hasPosted) willPostOn = moment(today).add(1, "day")
        }

        transactionsEntries.push({
          transactionId: transaction.id,
          type: transaction.type,
          forDate: moment(date),
          date: moment(adjustedDate),
          name: transaction.name,
          amount: adjustedTransaction.amount,
          fromAccount: getAccount(adjustedTransaction.fromAccountId),
          toAccount: getAccount(adjustedTransaction.toAccountId),
          repeatsDaily: transaction.repeats?.interval === 1 && transaction.repeats?.frequency === "days",
          hasPosted,
          willPostOn
        })
      }
    })

    // Sort by date
    // If date is the same, sort repeats daily last, then income first, then transfer second
    transactionsEntries.sort((a, b) => {
      if (a.date.isSame(b.date)) {
        if (a.repeatsDaily === b.repeatsDaily) {
          const aTypeOrder = a.type === "income" ? 1 : (a.type === "transfer" ? 2 : 3)
          const bTypeOrder = b.type === "income" ? 1 : (b.type === "transfer" ? 2 : 3)

          return aTypeOrder > bTypeOrder ? 1 : -1
        }
        else return a.repeatsDaily ? 1 : -1
      }
      else return a.date.isAfter(b.date) ? 1 : -1
    })

    // Group by date
    transactionsEntries.forEach(entry => {
      // Add current and past transactions to recentTransactionEntries
      if (today.isSameOrAfter(entry.date) && !entry.repeatsDaily)
        recentTransactionEntries.push(entry)

      // And pending and future transactions to transactionEntriesGrouped
      const isPending = today.isSameOrAfter(entry.date) && !entry.hasPosted
      const isFuture = today.isBefore(entry.date)

      if (isPending || isFuture) {
        if (isPending) entry = {...entry, date: moment(today)}

        const monthKey = isPending ? "pending" : entry.date.format("YYYY-MM")
        const dateKey = entry.date.format("YYYY-MM-DD")

        if (!transactionEntriesGrouped[monthKey]) transactionEntriesGrouped[monthKey] = {}
        if (!transactionEntriesGrouped[monthKey][dateKey]) transactionEntriesGrouped[monthKey][dateKey] = []

        // Add balances to entry
        if (entry.fromAccount) balances[entry.fromAccount.id] -= entry.amount
        if (entry.toAccount) balances[entry.toAccount.id] += entry.amount

        transactionEntriesGrouped[monthKey][dateKey].push({...entry, balances: {...balances}})
      }
    })

    return [transactionEntriesGrouped, recentTransactionEntries]
  }, [accountIds, filteredTransactions, startDate, endDate, today, getAccount])

  const hasBalancesAndPendingsData = useMemo(() => !!(accounts.length || recentTransactionEntries.length), [accounts, recentTransactionEntries])

  useEffect(() => {
    if (isBalancesAndPendingsOpen) {
      WsServer.sendMessage({
        method: "updateSettings",
        settings: {balancesAndPendingsLastOpenedAt: (new Date).getTime() / 1000} // eslint-disable-line new-parens
      })
    }
  }, [isBalancesAndPendingsOpen])

  useEffect(() => {
    const shouldAutoOpenBalancesAndPendings =
      hasBalancesAndPendingsData &&
      (
        !settings.balancesAndPendingsLastOpenedAt ||
        !moment.unix(settings.balancesAndPendingsLastOpenedAt).startOf("day").isSame(today)
      )

    shouldAutoOpenBalancesAndPendings && setIsBalancesAndPendingsOpen(true)
  }, [settings, today, hasBalancesAndPendingsData])

  // Close balances and pendings if it's open and backend send info to show no data within balances and pendings
  useEffect(() => {
    if (isBalancesAndPendingsOpen && !hasBalancesAndPendingsData)
      setIsBalancesAndPendingsOpen(false)
  }, [isBalancesAndPendingsOpen, hasBalancesAndPendingsData])

  // Close edit transaction entry modal if transaction is deleted from backend
  useEffect(() => {
    if (editEntry && !filteredTransactions.filter(transaction => transaction.id === editEntry.transactionId).length)
      setEditEntry(null)
  }, [editEntry, filteredTransactions])

  const renderEntry = entry  => (
    <tr key={entry.transactionId} className="pointer" onClick={() => setEditEntry(entry)}>
      <td>{entry.name}</td>
      <td>{entry.type === "expense" ? "-" : null}${entry.amount.toLocaleString()}</td>
      {accountIds.map(accountId => {
        const balance = Math.floor(entry.balances[accountId])

        return (
          <td key={accountId} className={entry.fromAccount?.id !== +accountId && entry.toAccount?.id !== +accountId ? "muted-2" : null}>
            {balance < 0 ? "-" : null}${Math.abs(balance).toLocaleString()}
          </td>
        )
      })}
    </tr>
  )

  const renderDailyTransactionsRow = (dateKey, dateGroup) => {
    // Render the row showing the sum of all daily transactions
    // TODO: entry type "transfer" will show incorrect results... but user is not allowed to make a daily transfer anyways, so it doesn't matter

    const lastEntry = dateGroup[dateGroup.length - 1]
    const areDailyTransactionsExpanded = dailyTransactionsOpened.includes(dateKey)

    if (!lastEntry.repeatsDaily) return null // There are no daily transactions, so don't show anything

    const amountSum = Object.values(dateGroup)
      .filter(entry => entry.repeatsDaily)
      .map(entry => entry.type === "expense" ? (-1 * entry.amount) : entry.amount)
      .reduce((a, b) => a + b, 0)

    const effectedAccountIds = new Set([])
    dateGroup
      .filter(entry => entry.repeatsDaily)
      .forEach(entry => {
        if (entry.fromAccount) effectedAccountIds.add(entry.fromAccount.id)
        if (entry.toAccount) effectedAccountIds.add(entry.toAccount.id)
      })

    return (
      <tr
        key="daily-transactions"
        className="pointer"
        onClick={() => {
          // Toggle
          if (areDailyTransactionsExpanded)
            setDailyTransactionsOpened([...dailyTransactionsOpened.filter(o => o !== dateKey)])
          else
            setDailyTransactionsOpened([...dailyTransactionsOpened, dateKey])
        }}
      >
        <td>
          <span className={areDailyTransactionsExpanded ? "underline" : null}>
            Daily Transactions
          </span>
        </td>
        <td>
          {!areDailyTransactionsExpanded ? (<>
            {amountSum < 0 ? "-" : null}${Math.abs(amountSum).toLocaleString()}
          </>) : null}
        </td>
        {accountIds.map(accountId => {
          const balance = Math.floor(lastEntry.balances[accountId])

          return (
            <td key={accountId} className={!effectedAccountIds.has(+accountId) ? "muted-2" : null}>
              {!areDailyTransactionsExpanded ? (<>
                {balance < 0 ? "-" : null}${Math.abs(balance).toLocaleString()}
              </>) : null}
            </td>
          )
        })}
      </tr>
    )
  }

  return (<>
    <div className="p-2">
      <h2 className="block">
        Dashboard
        {hasBalancesAndPendingsData ? (
          <button className="ml-4" onClick={() => setIsBalancesAndPendingsOpen(true)}>Update Details</button>
        ) : null}
      </h2>

      Show: {accounts.map(account => (
        <label key={account.id} htmlFor={`account-${account.id}`} className="my-4 mx-2">
          <input
            id={`account-${account.id}`}
            type="checkbox"
            checked={accountIds.includes(account.id.toString())}
            onChange={() => {
              const selectedAccountIds = accountIds.map(id => +id)

              if (selectedAccountIds.includes(account.id))
                selectedAccountIds.splice(selectedAccountIds.indexOf(account.id), 1)
              else
                selectedAccountIds.push(account.id)

              const newAccountIds = [...accounts.filter(a => selectedAccountIds.includes(a.id)).map(a => a.id.toString())]

              localStorage.setItem("accountIds", JSON.stringify(newAccountIds))
              setAccountIds(newAccountIds)
            }}
          /> {account.name}
        </label>
      ))}

      {Object.keys(transactionEntriesGrouped).length ? (
        <div className="table-overflow">
          <table className="striped">
            {Object.entries(transactionEntriesGrouped).map(([monthKey, monthGroup]) => {
              const monthlySum = Object.values(monthGroup)
                .map(dateGroup => (
                  Object.values(dateGroup)
                    .map(entry => {
                      if (entry.type === "transfer") {
                        if (!accountIds.includes(entry.toAccount.id.toString())) // To account is not selected by user
                          return -1 * entry.amount

                        if (!accountIds.includes(entry.fromAccount.id.toString())) // From account is not selected by user
                          return entry.amount

                        return 0 // Both to and from accounts are select by user, so a transfer equals $0 change in balance
                      }

                      if (entry.type === "expense")
                        return -1 * entry.amount

                      return entry.amount
                    })
                    .reduce((a, b) => a + b, 0)
                ))
                .reduce((a, b) => a + b, 0)

              return (
                <Fragment key={monthKey}>
                  <thead>
                  <tr>
                    <th colSpan={2 + accountIds.length}>
                      <div
                        className="pointer flex align-items-center"
                        onClick={() => {
                          const index = monthsOpened.indexOf(monthKey)

                          if (index !== -1) {
                            monthsOpened.splice(index, 1)
                            setMonthsOpened([...monthsOpened])
                          }
                          else setMonthsOpened([...monthsOpened, monthKey])
                        }}
                      >
                        <Icon type={monthsOpened.includes(monthKey) ? "minus-square" : "plus-square"} solid />
                        <h3 className="mb-0 ml-2">
                          {monthKey === "pending" ? "Pending" : moment(monthKey).format("MMMM YYYY")}
                        </h3>
                        <div className="ml-2 weight-normal">
                          {monthlySum < 0 ? "-" : "+"}${Math.abs(monthlySum).toLocaleString()}
                        </div>
                      </div>
                    </th>
                  </tr>
                  {monthsOpened.includes(monthKey) ? (
                    <tr>
                      <th>Name</th>
                      <th>Amount</th>
                      {accountIds.map(accountId => (
                        <th key={accountId}>{getAccount(accountId).name}</th>
                      ))}
                    </tr>
                  ) : null}
                  </thead>

                  {monthsOpened.includes(monthKey) ? (<>
                    {Object.entries(monthGroup).map(([dateKey, dateGroup]) => (
                      <Fragment key={dateKey}>
                        <tbody>
                        {monthKey !== "pending" ? (
                          <tr>
                            <td colSpan={2 + accountIds.length}>
                              <div className="weight-semibold">{moment(dateKey).format("M/D/YY - dddd")}</div>
                            </td>
                          </tr>
                        ) : null}
                        {dateGroup.filter(entry => !entry.repeatsDaily).map(entry => renderEntry(entry))}
                        {renderDailyTransactionsRow(dateKey, dateGroup)}
                        {dailyTransactionsOpened.includes(dateKey) ? (
                          dateGroup.filter(entry => entry.repeatsDaily).map(entry => renderEntry(entry))
                        ) : null}
                        </tbody>
                      </Fragment>
                    ))}
                  </>) : null}
                </Fragment>
              )
            })}
          </table>
        </div>
      ) : (
        <div className="my-16 center">There's nothing to show <Icon type="sad" /></div>
      )}
    </div>

    {isBalancesAndPendingsOpen ? (
      <Modal small onRequestClose={() => setIsBalancesAndPendingsOpen(false)}>
        <BalancesAndPendingTransactions
          recentTransactionEntries={recentTransactionEntries}
          onRequestClose={() => setIsBalancesAndPendingsOpen(false)}
        />
      </Modal>
    ) : null}

    {editEntry && (
      <Modal onRequestClose={() => setEditEntry(null)}>
        <TransactionForm
          type={editEntry.type}
          transactionId={editEntry.transactionId}
          onSubmit={() => setEditEntry(null)}
        />
      </Modal>
    )}
  </>)
}

const mapStateToProps = state => ({
  settings: state.worksheet.settings,
  accounts: getSortedAccounts(state),
  getAccount: accountId => getAccount(state, accountId),
  transactions: state.worksheet.transactions
})

export default connect(mapStateToProps)(Dashboard)

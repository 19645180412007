import { useState, useEffect, useCallback } from 'react'
import { MantineProvider, JsonInput } from '@mantine/core'

import './Config.scss'

function Config () {
  const [initialized, setInitialized] = useState(false)
  const [configJson, setConfigJson] = useState()
  const [isValidJson, setIsValidJson] = useState(true)

  useEffect(() => {
    fetch(`${ window.location.protocol }//${ window.location.hostname }:2053/api/envelopes/config`)
      .then(r => r.json())
      .then(data => {
        setInitialized(true)
        setConfigJson(JSON.stringify(data, null, 2))
      })
  }, [])

  const save = useCallback(() => {
    fetch(
      `${ window.location.protocol }//${ window.location.hostname }:2053/api/envelopes/config`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(JSON.parse(configJson))
      },
    )
      .then(r => r.json())
      .then(({ success }) => success && alert('Saved!'))
  }, [configJson])

  return initialized ? (
    <div>
      <MantineProvider>
        <JsonInput
          validationError="Invalid JSON"
          formatOnBlur
          value={configJson}
          onChange={json => {
            try {
              JSON.parse(json)
              setIsValidJson(true)
            } catch(e) {
              setIsValidJson(false)
            }
            setConfigJson(json)
          }}
          classNames={{ input: 'json-input', error: 'json-error' }}
        />
        <div style={{ textAlign: 'center' }}>
          <button onClick={save} disabled={!isValidJson} className="mt-2">
            {isValidJson ? 'Save' : 'Invalid JSON'}
          </button>
        </div>
      </MantineProvider>
    </div>
  ) : null
}

export default Config

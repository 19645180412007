import React, { useState, useRef, useMemo, useCallback, useEffect } from "react"
import { connect } from "react-redux"
import moment from "moment"

import Adjustments from "./Adjustments"
import AccountSelect from "./AccountSelect"
import { getAccount, getTransaction } from "../store"
import { renameIfDuplicate, sanitizeMoneyInput, sanitizeIntegerInput } from "../helpers"
import * as WsServer from "../services/WsServer"

const TransactionForm = ({getAccount, transactions, getTransaction, type, transactionId, onSubmit, onRequestCancel}) => {
  const transaction = useMemo(() => (transactionId && getTransaction(transactionId)) || {}, [getTransaction, transactionId])

  const defaultValues = useMemo(() => {
    const fromAccountId = (transaction.fromAccountId && getAccount(transaction.fromAccountId)) ?
      transaction.fromAccountId.toString() :
      (type === "expense" || type === "transfer" ? "default" : "")

    const toAccountId = (transaction.toAccountId && getAccount(transaction.toAccountId)) ?
      transaction.toAccountId.toString() :
      (type === "income" || type === "transfer" ? "default" : "")

    return {
      name: transaction.name || "",
      amount: transaction.amount?.toString() || "",
      date: transaction.date || moment().format("YYYY-MM-DD"),
      fromAccountId,
      toAccountId,
      repeats: !!transaction.repeats,
      repeatInterval: transaction.repeats?.interval.toString() || "1",
      repeatFrequency: transaction.repeats?.frequency || "months", // days, weeks, months, years
      repeatEnds: !!transaction.repeats?.endDate,
      repeatEndDate: transaction.repeats?.endDate || "",
      notes: transaction.notes || ""
    }
  }, [type, transaction, getAccount])

  const [name, setName] = useState(defaultValues.name)
  const [amount, setAmount] = useState(defaultValues.amount)
  const [date, setDate] = useState(defaultValues.date)
  const [fromAccountId, setFromAccountId] = useState(defaultValues.fromAccountId)
  const [toAccountId, setToAccountId] = useState(defaultValues.toAccountId)
  const [repeats, setRepeats] = useState(defaultValues.repeats)
  const [repeatInterval, setRepeatInterval] = useState(defaultValues.repeatInterval)
  const [repeatFrequency, setRepeatFrequency] = useState(defaultValues.repeatFrequency)
  const [repeatEnds, setRepeatEnds] = useState(defaultValues.repeatEnds)
  const [repeatEndDate, setRepeatEndDate] = useState(defaultValues.repeatEndDate)
  const [notes, setNotes] = useState(defaultValues.notes)
  const [confirmDeleteTimeout, setConfirmDeleteTimeout] = useState(null)

  const nameElem = useRef(null)
  const repeatIntervalElem = useRef(null)
  const repeatEndDateElem = useRef(null)

  const isEditing = !!transaction.id

  const reset = useCallback(() => {
    setName(defaultValues.name)
    setAmount(defaultValues.amount)
    setDate(defaultValues.date)
    setFromAccountId(defaultValues.fromAccountId)
    setToAccountId(defaultValues.toAccountId)
    setRepeats(defaultValues.repeats)
    setRepeatInterval(defaultValues.repeatInterval)
    setRepeatFrequency(defaultValues.repeatFrequency)
    setRepeatEnds(defaultValues.repeatEnds)
    setRepeatEndDate(defaultValues.repeatEndDate)
    setNotes(defaultValues.notes)
  }, [defaultValues])

  const transactionsFiltered = useMemo(() => transactions.filter(transaction => transaction.type === type), [type, transactions])

  const areInputsValid = useMemo(() => (
    name &&
    amount &&
    moment(date).isValid() &&
    (type !== "transfer" || fromAccountId !== toAccountId) &&
    (!repeats || (repeatInterval && (!repeatEnds || moment(repeatEndDate).isValid())))
  ), [name, amount, date, type, fromAccountId, toAccountId, repeats, repeatInterval, repeatEnds, repeatEndDate])

  const inputsFormatted = useMemo(() => ({
    name,
    amount: +amount,
    date: (moment(date).isValid() ? moment(date) : moment()).format("YYYY-MM-DD"),
    fromAccountId: fromAccountId === "default" ? fromAccountId : (fromAccountId ? +fromAccountId : null),
    toAccountId: toAccountId === "default" ? toAccountId : (toAccountId ? +toAccountId : null),
    repeats: repeats ? {
      interval: +repeatInterval,
      frequency: repeatFrequency,
      endDate: repeatEnds && moment(repeatEndDate).isValid() ? moment(repeatEndDate).format("YYYY-MM-DD") : null
    } : null,
    notes
  }), [name, amount, date, fromAccountId, toAccountId, repeats, repeatInterval, repeatFrequency, repeatEnds, repeatEndDate, notes])

  const repeatingDatesEdited = useMemo(() => !!( // TODO: better name for this?
    isEditing &&
    transaction.repeats &&
    Object.keys(transaction.adjustments).length && (
      inputsFormatted.date !== transaction.date ||
      !!inputsFormatted.repeats !== !!transaction.repeats ||
      inputsFormatted.repeats.interval !== transaction.repeats.interval ||
      inputsFormatted.repeats.frequency !== transaction.repeats.frequency ||
      inputsFormatted.repeats.endDate !== transaction.repeats.endDate
    )
  ), [isEditing, transaction.adjustments, transaction.date, transaction.repeats, inputsFormatted.date, inputsFormatted.repeats])

  // Focus on input on mount
  useEffect(() => { setTimeout(() => nameElem.current.focus()) }, [])

  // Make sure fromAccountId and toAccountId are valid in-case accounts updates from backend
  useEffect(() => {
    if (fromAccountId && fromAccountId !== "default" && !getAccount(fromAccountId))
      setFromAccountId(defaultValues.fromAccountId)

    if (toAccountId && toAccountId !== "default" && !getAccount(toAccountId))
      setToAccountId(defaultValues.toAccountId)
  }, [fromAccountId, toAccountId, defaultValues.fromAccountId, defaultValues.toAccountId, getAccount])

  // Clear confirmDeleteTimeout when component closes
  useEffect(() => () => confirmDeleteTimeout && clearTimeout(confirmDeleteTimeout), [confirmDeleteTimeout])

  return (
    <div>
      <h3 className="block">{isEditing ? "Edit" : "Add New"} {type[0].toUpperCase() + type.slice(1)}</h3>
      <form
        onSubmit={e => {
          e.preventDefault()

          if (!areInputsValid) return

          WsServer.sendMessage({
            method: isEditing ? "updateTransaction" : "addTransaction",
            transaction: {
              ...transaction,
              adjustments: repeatingDatesEdited ? {} : transaction.adjustments,
              type,
              ...inputsFormatted,
              name: renameIfDuplicate(inputsFormatted.name, transactionsFiltered.filter(t => t.id !== transaction.id).map(transaction => transaction.name))
            }
          })

          !isEditing && reset()

          onSubmit()
        }}
      >
        <div className="form">
          <div>
            <div className="form-group">
              <label htmlFor="name">Name:</label>
              <input
                id="name"
                className="large"
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
                ref={nameElem}
              />
            </div>
          </div>

          <div>
            <div className="form-group">
              <label htmlFor="amount">{type === "income" ? "Deposit" : (type === "expense" ? "Withdraw" : "Transfer")}:</label>
              $
              <input
                id="amount"
                className="small"
                type="text"
                value={amount}
                onChange={e => setAmount(sanitizeMoneyInput(e.target.value))}
                disabled={transaction.managedByPlaid}
              />
            </div>
            <div className="form-group">
              <label htmlFor="date">On:</label>
              <input
                id="date"
                type="date"
                placeholder="YYYY-MM-DD"
                value={date}
                onChange={e => setDate(e.target.value)}
                disabled={transaction.managedByPlaid}
              />
            </div>
            {type !== "transfer" ? (
              <AccountSelect
                id={type === "income" ? "toAccountId" : "fromAccountId"}
                title={type === "income" ? "To" : "From"}
                value={type === "income" ? toAccountId : fromAccountId}
                onChange={e => type === "income" ? setToAccountId(e.target.value) : setFromAccountId(e.target.value)}
                disabled={transaction.managedByPlaid}
              />
            ) : null}
          </div>

          {type === "transfer" ? (
            <div>
              <AccountSelect id="fromAccountId" title="From" value={fromAccountId} onChange={e => setFromAccountId(e.target.value)} />
              <AccountSelect id="toAccountId" title="To" value={toAccountId} onChange={e => setToAccountId(e.target.value)} />
            </div>
          ) : null}

          <div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={repeats}
                  onChange={e => {
                    setRepeats(e.target.checked)

                    if (e.target.checked) setTimeout(() => repeatIntervalElem.current?.focus())
                  }}
                  disabled={transaction.managedByPlaid}
                />
                Repeat{repeats ? " Every:" : null}
              </label>
              {repeats ? (<>
                <input
                  className="x-small"
                  type="tel"
                  value={repeatInterval}
                  ref={repeatIntervalElem}
                  onChange={e => setRepeatInterval(sanitizeIntegerInput(e.target.value))}
                  disabled={transaction.managedByPlaid}
                />
                <select
                  className="small"
                  value={repeatFrequency}
                  onChange={e => setRepeatFrequency(e.target.value)}
                  disabled={transaction.managedByPlaid}
                >
                  {type !== "transfer" ? (
                    <option value="days">day{repeatInterval > 1 ? "s" : null}</option>
                  ) : null}
                  <option value="weeks">week{repeatInterval > 1 ? "s" : null}</option>
                  <option value="months">month{repeatInterval > 1 ? "s" : null}</option>
                  <option value="years">year{repeatInterval > 1 ? "s" : null}</option>
                </select>
              </>) : null}
            </div>
            {repeats ? (
              <div className="form-group">
                <label>
                  <input
                    type="checkbox"
                    checked={repeatEnds}
                    onChange={e => {
                      setRepeatEnds(e.target.checked)

                      if (e.target.checked) setTimeout(() => repeatEndDateElem.current?.focus())
                    }}
                    disabled={transaction.managedByPlaid}
                  />
                  Repeat Ends{repeatEnds ? ":" : null}
                </label>
                {repeatEnds ? (
                  <input
                    id="repeatEndDate"
                    type="date" /* TODO: freezes if year is 2002?? TODO: shows weird on iPhone when empty */
                    value={repeatEndDate}
                    ref={repeatEndDateElem}
                    onChange={e => setRepeatEndDate(e.target.value)}
                    disabled={transaction.managedByPlaid}
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          <div>
            <div className="form-group">
              <label htmlFor="notes" className="align-self-start">Notes:</label>
              <textarea id="notes" className="x-large" rows={3} value={notes} onChange={e => setNotes(e.target.value)} />
            </div>
          </div>

          <div>
            <div className="form-group">
              <div>
                {isEditing ? (
                  !transaction.managedByPlaid ? (
                    <button
                      type="button"
                      className={!confirmDeleteTimeout ? "secondary" : "danger"}
                      onClick={() => {
                        if (!confirmDeleteTimeout)
                          setConfirmDeleteTimeout(setTimeout(() => setConfirmDeleteTimeout(null), 2000))
                        else
                          WsServer.sendMessage({method: "removeTransaction", transactionId})
                      }}
                    >
                      Delete
                    </button>
                  ) : null
                ) : (
                  <button type="button" className="secondary" onClick={() => onRequestCancel()}>Cancel</button>
                )}
                <button type="submit" disabled={!areInputsValid}>{isEditing ? "Update" : "Add"}</button>
                {repeatingDatesEdited ? (
                  <div><small>All adjustments will be deleted after updating.</small></div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </form>

      {isEditing ? (
        <Adjustments transaction={transaction} disabled={transaction.managedByPlaid} />
      ) : null}
    </div>
  )
}

const mapStateToProps = state => ({
  getAccount: accountId => getAccount(state, accountId),
  transactions: state.worksheet.transactions,
  getTransaction: transactionId => getTransaction(state, transactionId)
})

export default connect(mapStateToProps)(TransactionForm)

import { useMemo } from 'react'
import envelopeFront from '../images/envelope-front.png'
import envelopeBack from '../images/envelope-back.png'
import bill100 from '../images/bill-100.png'
import bill50 from '../images/bill-50.png'
import bill20 from '../images/bill-20.png'
import bill10 from '../images/bill-10.png'
import bill5 from '../images/bill-5.png'
import bill1 from '../images/bill-1.png'

function Envelope({ name, balance, onClick, weeklyBudget, isOpen, className, width = 125 }) {
  const bills = useMemo(() => {
    let ceilBalance = Math.ceil(balance > 0 ? balance : 0)
    const bills = [
      {denomination: 100, amount: 0, image: bill100},
      {denomination: 50, amount: 0, image: bill50},
      {denomination: 20, amount: 0, image: bill20},
      {denomination: 10, amount: 0, image: bill10},
      {denomination: 5, amount: 0, image: bill5},
      {denomination: 1, amount: 0, image: bill1},
    ]
    for (let bill of bills) {
      bill.amount = Math.floor(ceilBalance / bill.denomination)
      ceilBalance -= bill.amount * bill.denomination
    }
    return bills.reverse()
  }, [balance])

  let rotate = 0
  const rotateTurn = isOpen ? (.14 / bills.map(bill => bill.amount).reduce((a, b) => a + b, 0)) : 0

  return (
    <div
      className={`relative ${className ? className : ''}`}
      onClick={() => onClick && onClick()}
      style={{width}}
    >
      <img src={envelopeBack} alt="" className="full-width" style={{filter: 'hue-rotate(87deg) contrast(0.9)'}} />
      {bills.map(bill => (
        Array(bill.amount).fill().map((a, i) => (
          <img
            key={`${bill.denomination}-${i}`}
            src={bill.image}
            alt=""
            className="absolute"
            style={{
              top: isOpen ? '15%' : '40%',
              left: '10%',
              width: '80%',
              transform: `rotate(${rotate = rotate + rotateTurn}turn)`,
            }}
          />
        ))
      ))}
      <img src={envelopeFront} alt="" className="full-width absolute" style={{top: 0, left: 0, filter: 'brightness(100)'}} />
      <div
        className="absolute full-width flex flex-column justify-content-center align-items-center"
        style={{
          top: '47%',
          bottom: '16%',
          width: '88%',
          left: '6%',
          color: 'deepskyblue',
          textAlign: 'center',
          fontFamily: 'Kalam, cursive',
          fontSize: 18,
          lineHeight: 1.2,
        }}
      >
        {
          !isOpen && name.indexOf(' (') !== -1
            ? (<>{name.split(' (')[0]}<br/>({name.split(' (')[1]}</>)
            : name
        }
        {isOpen ? (<>
          <br />
          {(Math.round(balance * 100) / 100).toLocaleString('en-US', {style: 'currency', currency: 'USD'})}
          <br />
          <small>+${weeklyBudget}/week</small>
        </>) : null}
      </div>
    </div>
  )
}

export default Envelope

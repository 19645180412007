import { useEffect, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Envelope from '../components/Envelope'

function OpenedEnvelope({ envelopes }) {
  const location = useLocation()
  const navigate = useNavigate()

  const envelope = useMemo(() => {
    const envelopeId = location.pathname.substring('/envelopes/'.length)

    return envelopes[envelopeId]
  }, [location.pathname, envelopes])

  // Scroll to top
  useEffect(() => {
    const timer = setInterval(() => window.scroll(0, 0), 1)
    const stopTimer = () => clearInterval(timer)

    setTimeout(stopTimer, 250)

    return stopTimer
  }, [])

  return (
    <div>
      <div className="absolute p-4" style={{top: 0, right: 0, lineHeight: 1, fontSize: 24}} onClick={() => navigate('/envelopes/all')}>
        &#x2715;
      </div>
      <div style={{padding: '50px 0'}}>
        {envelope ? (
          <Envelope
            name={envelope.name}
            balance={envelope.balance}
            weeklyBudget={envelope.weeklyBudget}
            isOpen={true}
            className="m-auto"
            width={200}
          />
        ) : null}
      </div>
    </div>
  );
}

export default OpenedEnvelope

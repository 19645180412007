import React from "react"
import { connect } from "react-redux"

import { getSortedAccounts, getDefaultAccountName } from "../store"

const AccountSelect = ({accounts, defaultAccountName, id, title, value, onChange, disabled}) => {
  return (
    <div className="form-group">
      <label htmlFor={id}>{title}:</label>
      <select id={id} value={value} onChange={onChange} disabled={disabled}>
        <option value="default">{defaultAccountName}</option>
        {accounts.map(account => <option key={account.id} value={account.id}>{account.name}</option>)}
      </select>
    </div>
  )
}

const mapStateToProps = state => ({
  accounts: getSortedAccounts(state),
  defaultAccountName: getDefaultAccountName(state)
})

export default connect(mapStateToProps)(AccountSelect)

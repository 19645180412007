import React from "react"
import ReactModal from "react-modal"
import classNames from "classnames"

import "./Modal.scss"

const Modal = ({small = false, hideClose, onRequestClose, children}) => {
  return (
    <ReactModal
      portalClassName="modal-component"
      overlayClassName="overlay"
      className={classNames("modal", small && "small")}
      isOpen
      onRequestClose={onRequestClose}
      ariaHideApp={false}
    >
      {!hideClose ? (
        <div className="close" onClick={onRequestClose}>&times;</div>
      ) : null}
      <div className="body">{children}</div>
    </ReactModal>
  )
}

export default Modal

import React from 'react'
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import App from "./App"
import EnvelopesApp from "./EnvelopesApp"
import store from "./store"

import "./styles/style.scss"

const root = createRoot(document.getElementById('root'))

if (window.location.pathname.startsWith("/envelopes"))
  root.render(<BrowserRouter><EnvelopesApp /></BrowserRouter>)
else
  root.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>)

// TODO: auto back up databases when in AWS

import { useState, useEffect, useCallback } from 'react'

function Logs () {
  const [logs, setLogs] = useState()

  useEffect(() => {
    fetch(`${ window.location.protocol }//${ window.location.hostname }:2053/api/envelopes/logs`)
      .then(r => r.json())
      .then(data => setLogs(JSON.stringify(data, null, 2)))
  }, [])

  return logs ? (
    <div>
      <pre>{ logs }</pre>
    </div>
  ) : null
}

export default Logs

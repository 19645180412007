export const renameIfDuplicate = (name, previousNames = []) => {
  let newName = name
  let increment = 1

  while (previousNames.map(n => n.toLowerCase().trim()).includes(newName.toLowerCase().trim()))
    newName = name.trim() + ` (${increment++})`

  return newName
}

export const sanitizeMoneyInput = input => {
  // Remove all chars besides numbers and decimals
  input = input.split("").filter(char => ["0","1","2","3","4","5","6","7","8","9","."].includes(char)).join("")

  // Only allow one decimal, and up to 2 digits after the decimal
  if (input.indexOf(".") !== -1)
    return input.split(".")[0] + "." + (input.split(".")[1] || "").slice(0, 2)
  else
    return input
}

export const sanitizeIntegerInput = input => {
  // Remove all chars besides numbers
  return input.split("").filter(char => ["0","1","2","3","4","5","6","7","8","9"].includes(char)).join("")
}

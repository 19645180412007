import React, { useState, useEffect, useMemo } from 'react'
import { connect } from 'react-redux'
import { Routes, Route, Navigate, Link, useLocation } from 'react-router-dom'
import moment from 'moment'

import Dashboard from "./containers/Dashboard"
import Accounts from "./containers/Accounts"
import Transactions from "./containers/Transactions"
import Modal from "./components/Modal"
import Icon from "./components/Icon"
import * as WsServer from "./services/WsServer"
import { setWorksheet } from "./store"

import "./App.scss"

function App({ setWorksheet }) {
  const location = useLocation()

  const [isInitialized, setIsInitialized] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  // Connect to backend server
  useEffect(() => { WsServer.connect(2083) }, [])

  const worksheetId = useMemo(() => {
    if (window.location.pathname.split("/")[1])
      return window.location.pathname.split("/")[1]

    const chars = "ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnpqrstuvwxyz23456789"

    let key = ""
    for (let i = 0; i < 12; i++)
      key += chars.charAt(Math.floor(Math.random() * chars.length))

    return key
  }, [])

  // Communicate with backend to initialize and setWorksheet
  useEffect(() => {
    if (!worksheetId) return

    const onOpenListener = () => WsServer.sendMessage({method: "initialize", worksheetId})
    WsServer.addOnOpenListener(onOpenListener)

    const onMessageListener = message => {
      if (message.method === "setWorksheet") {
        setWorksheet(message.worksheet)

        setIsInitialized(true)
      }
    }
    WsServer.addOnMessageListener(onMessageListener)

    return () => {
      WsServer.removeOnOpenListener(onOpenListener)
      WsServer.removeOnMessageListener(onMessageListener)
    }
  }, [worksheetId, setWorksheet])

  // Close menu on navigation
  useEffect(() => { setIsMenuOpen(false) }, [location.pathname])

  // Refresh on new day, so date variables are updated in containers and components
  useEffect(() => {
    setTimeout(() => window.location.reload(), +moment().endOf("day") - +moment() + 1)
  }, [])

  // TODO: handle browser back/forward button? Back: close modal, close adjustment form if open
  // TODO: backup data automatically
  return (
    <div className="app">
      <Icon type="menu" className="menu-toggle" onClick={() => setIsMenuOpen(!isMenuOpen)} />

      <Routes>
        <Route path="/" exact element={<Navigate to={`/${worksheetId}`} />} />
        <Route path="/:worksheetId" exact element={isInitialized ? (<Dashboard />) : null} />
        <Route path="/:worksheetId/accounts" exact element={isInitialized ? (<Accounts />) : null} />
        <Route path="/:worksheetId/incomes" exact element={isInitialized ? (<Transactions type="income" />) : null} />
        <Route path="/:worksheetId/expenses" exact element={isInitialized ? (<Transactions type="expense" />) : null} />
        <Route path="/:worksheetId/transfers" exact element={isInitialized ? (<Transactions type="transfer" />) : null} />
      </Routes>

      {isMenuOpen ? (
        <Modal small onRequestClose={() => setIsMenuOpen(false)}>
          <div className="full-height flex flex-column justify-content-center pt-8">
            <Link className="btn block" to={`/${worksheetId}`}>Dashboard</Link>
            <Link className="btn block" to={`/${worksheetId}/accounts`}>Manage Accounts</Link>
            <Link className="btn block" to={`/${worksheetId}/incomes`}>Manage Incomes</Link>
            <Link className="btn block" to={`/${worksheetId}/expenses`}>Manage Expenses</Link>
            <Link className="btn block" to={`/${worksheetId}/transfers`}>Manage Transfers</Link>
          </div>
        </Modal>
      ) : null}
    </div>
  )
}

const mapDispatchToProps = dispatch => ({setWorksheet: worksheet => dispatch(setWorksheet(worksheet))})

export default connect(null, mapDispatchToProps)(App)

import Envelope from '../components/Envelope'
import { useNavigate } from 'react-router-dom'

function Envelopes ({ envelopes }) {
  const navigate = useNavigate()

  return (
    <div className="flex flex-wrap justify-content-around">
      {Object.entries(envelopes).filter(([envelopeId]) => envelopeId !== 'ignore').map(([envelopeId, envelope]) => (
        <Envelope
          key={envelopeId}
          name={envelope.name}
          balance={envelope.balance}
          onClick={() => navigate(`/envelopes/${envelopeId}`)}
        />
      ))}
    </div>
  );
}

export default Envelopes

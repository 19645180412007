import React, { useState, useEffect, useMemo } from 'react'
import { Routes, Route, Navigate, useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment'
import './index.scss'
import Envelopes from './containers/Envelopes'
import Entries from './containers/Entries'
import OpenedEnvelope from './containers/OpenedEnvelope'
import Config from './containers/Config'
import Logs from './containers/Logs'
import * as WsServer from '../services/WsServer'

function EnvelopesApp() {
  const [envelopes, setEnvelopes] = useState({})
  const [entries, setEntries] = useState([])
  const [accounts, setAccounts] = useState({})

  const background = '#bcd2e8'

  useEffect(() => { WsServer.connect(2087) }, [])

  useEffect(() => {
    const onMessageListener = message => {
      if (message.method === 'setDatabase') {
        setEnvelopes(message.envelopes)
        setEntries(
          [
            ...message.transactions.map(t => ({ ...t, type: 'transaction' })),
            ...message.transfers.map(t => ({ ...t, type: 'transfer' })),
          ]
            .sort((a, b) => +new Date(a.date) > +new Date(b.date) ? 1 : -1)
        )
        setAccounts(message.accounts)
      }
    }
    WsServer.addOnMessageListener(onMessageListener)

    return () => WsServer.removeOnMessageListener(onMessageListener)
  }, [])

  // Refresh on new day
  useEffect(() => {
    const timer = setTimeout(() => window.location.reload(), +moment().endOf('day') - +moment() + 1)

    return () => clearTimeout(timer)
  }, [])

  // Hack to set background
  useEffect(() => { document.body.style.background = background }, [])

  return (
    <Routes>
      <Route path="/envelopes" exact element={<Navigate to="/envelopes/transactions" />} />
      <Route
        path="/envelopes/all"
        exact
        element={(
          <PageContainer background={background}>
            <Envelopes envelopes={envelopes} />
          </PageContainer>
        )}
      />
      <Route
        path="/envelopes/transactions"
        exact
        element={(
          <PageContainer background={background}>
            <Entries
              entries={entries}
              accounts={accounts}
              envelopes={envelopes}
              background={background}
              onAddCategory={({ transactionId, envelopeId }) => {
                WsServer.sendMessage({
                  method: 'addCategory',
                  transactionId,
                  envelopeId,
                })
              }}
              onTransfer={({ from, to, amount }) => {
                WsServer.sendMessage({
                  method: 'addTransfer',
                  fromEnvelopeId: from,
                  toEnvelopeId: to,
                  amount,
                })
              }}
            />
          </PageContainer>
        )}
      />
      <Route path="/envelopes/config" exact element={<Config />} />
      <Route path="/envelopes/logs" exact element={<Logs />} />
      <Route
        path="/envelopes/:worksheetId"
        exact
        element={(
          <div className="envelopes-app">
            {envelopes ? (
              <OpenedEnvelope envelopes={envelopes} />
            ) : null}
          </div>
        )}
      />
    </Routes>
  )
}

function PageContainer({ children, background }) {
  const footerHeight = 80

  const navigate = useNavigate()
  const location = useLocation()

  const page = useMemo(() => {
    if (location.pathname === '/envelopes/all')
      return 'envelopes'
    if (location.pathname === '/envelopes/transactions')
      return 'transactions'
  }, [location.pathname])

  return (
    <div className="envelopes-app">
      <div>
        <div style={{ marginBottom: footerHeight }}>
          { children }
        </div>
        <div
          className="flex fixed full-width"
          style={{ bottom: 0, height: footerHeight, background, borderTop: '1px solid white', zIndex: 500 }}
        >
          {['Envelopes', 'Transactions'].map(nav => (
            <div
              key={nav}
              className="flex align-items-center justify-content-center"
              style={{
                width: '50%',
                background: page === nav.toLowerCase() ? 'white' : 'rgba(255, 255, 255, .5)',
                fontSize: 20
              }}
              onClick={() => navigate(nav === 'Envelopes' ? '/envelopes/all' : (nav === 'Transactions' ? '/envelopes/transactions' : '/envelopes'))}
            >
              {nav}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default EnvelopesApp

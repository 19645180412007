import React, { useState } from 'react'
import { sanitizeMoneyInput } from '../../helpers'

function Transfer({ envelopes, onClose, onSubmit }) {
  const [from, setFrom] = useState('')
  const [to, setTo] = useState('')
  const [amount, setAmount] = useState('')

  return (
    <>
      <div className="center"><h3>Transfer</h3></div>
      <div className="form-group flex mb-4">
        <label htmlFor="from">From:</label>
        <select id="from" value={from} onChange={e => setFrom(e.target.value)}>
          <option></option>
          {Object.entries(envelopes).map(([envelopeId, { name, balance }]) => (
            <option
              key={envelopeId}
              value={envelopeId}
            >
              {name} [{balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}]
            </option>
          ))}
        </select>
      </div>
      <div className="form-group flex mb-4">
        <label htmlFor="to">To:</label>
        <select id="to" value={to} onChange={e => setTo(e.target.value)}>
          <option></option>
          {Object.entries(envelopes).map(([envelopeId, { name, balance }]) => (
            <option
              key={envelopeId}
              value={envelopeId}
            >
              {name} [{balance.toLocaleString('en-US', { style: 'currency', currency: 'USD' })}]
            </option>
          ))}
        </select>
      </div>
      <div className="form-group flex mb-8">
        <label htmlFor="amount">Amount:</label>
        $
        <input
          id="amount"
          type="text"
          value={amount}
          onChange={e => setAmount(sanitizeMoneyInput(e.target.value))}
        />
      </div>
      <div className="form-group center">
        <button type="button" className="secondary" onClick={() => onClose && onClose()}>
          Cancel
        </button>
        <button
          type="submit"
          disabled={!to || !from || !amount}
          onClick={() => onSubmit && onSubmit({ from, to, amount: +amount })}
        >
          Submit
        </button>
      </div>
    </>
  )
}

export default Transfer

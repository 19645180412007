import { createStore } from "redux"

const initialState = {worksheet: null}

export default createStore((state = initialState, action) => {
  switch(action.type) {
    case "SET_WORKSHEET":
      return {...state, worksheet: action.payload.worksheet}
    default:
      return state
  }
})

export const setWorksheet = worksheet => ({
  type: "SET_WORKSHEET",
  payload: {worksheet}
})

// Selectors
export const getAccount = (state, accountId) => {
  if (!accountId || !state.worksheet.accounts) return

  if (accountId === "default")
    return state.worksheet.accounts.filter(account => account.isDefault)[0] || state.worksheet.accounts[0]
  else
    return state.worksheet.accounts.filter(account => account.id === +accountId)[0]
}

export const getTransaction = (state, transactionId) => state.worksheet.transactions?.filter(transaction => transaction.id === transactionId)[0]

export const getSortedAccounts = state => state.worksheet.accounts?.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1) || null

export const getPlaidLinks = state => state.worksheet.plaidLinks || null

export const getDefaultAccountName = (state) => {
  const defaultAccount = getAccount(state, "default")

  return defaultAccount ? `Default (${defaultAccount.name})` : "Default Account"
}

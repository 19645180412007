import React from 'react'
import classNames from 'classnames'
import { ReactSVG } from 'react-svg'

import "./Icon.scss"

const Icon = ({ type, solid = false, className, onClick }) => (
  <ReactSVG
    className={classNames(
      "icon-component",
      onClick && "pointer",
      className
    )}
    src={require(`boxicons/svg/${solid ? "solid" : "regular"}/${solid ? "bxs-" : "bx-"}${type}.svg`)}
    alt={type}
    onClick={onClick || null}
  />
)

export default Icon

import React, { useState, useMemo } from "react"
import { connect } from "react-redux"
import moment from "moment"
import classNames from "classnames"

import * as WsServer from "../services/WsServer"
import { sanitizeMoneyInput } from "../helpers"

const BalancesAndPendingTransactions = ({accounts, recentTransactionEntries, onRequestClose}) => {
  const [currentBalances, setCurrentBalances] = useState(Object.assign({}, ...accounts.map(account => ({[account.id]: account.currentBalance}))))
  const [isUpdated, setIsUpdated] = useState(false)

  const today = useMemo(() => moment().startOf("day"), [])
  const recentTransactionEntriesGrouped = useMemo(() => {
    const pending = recentTransactionEntries.filter(entry => !entry.hasPosted)
    const posted = recentTransactionEntries.filter(entry => entry.hasPosted).reverse()
    const recentTransactionEntriesGrouped = []

    if (pending.length) recentTransactionEntriesGrouped.push(pending)
    if (posted.length) recentTransactionEntriesGrouped.push(posted)

    return recentTransactionEntriesGrouped
  }, [recentTransactionEntries])

  return (<>
    {accounts.length ? (<>
      <h3>Current Balance</h3>
      <form
        onSubmit={e => {
          e.preventDefault()

          let wsError = false
          accounts.forEach(account => {
            try {
              WsServer.sendMessage({
                method: "updateAccount",
                account: {...account, currentBalance: +currentBalances[account.id]}
              })
            } catch (e) {
              wsError = true
            }
          })

          if (!wsError) {
            setIsUpdated(true)
            setTimeout(() => setIsUpdated(false), 2000) // TODO: stop timer if component has unmounted
          }
        }}
      >
        <div className="form">
          {accounts.map(account => (
            <div key={account.id} className="form-group">
              <label htmlFor={`account-${account.id}`}>{account.name}:</label>
              $<input id={`account-${account.id}`} className="small" type="text" value={currentBalances[account.id] !== undefined ? currentBalances[account.id] : 0} onChange={e => setCurrentBalances({...currentBalances, [account.id]: sanitizeMoneyInput(e.target.value)})} />
            </div>
          ))}
          <div>
            <div className="form-group">
              <button type="submit">{isUpdated ? "Updated!" : "Update"}</button>
            </div>
          </div>
        </div>
      </form>
    </>) : null}

    {recentTransactionEntriesGrouped.length ? (
      <table className={classNames("striped", accounts.length && "mt-8")}>
        {recentTransactionEntriesGrouped.map(group => (
          <tbody key={group[0].hasPosted ? "posted" : "pending"}>
          <tr>
            <td colSpan="2">
              <h3 className="mb-0">{group[0].hasPosted ? "Recently Posted" : "Pending"} Transactions</h3>
            </td>
          </tr>
          {group.map(entry => (
            <tr key={entry.transactionId.toString() + entry.forDate.format("YYYY-MM-DD")}>
              <td className="full-width">
                <div>{entry.name}</div>
                <div>{entry.type === "expense" ? "-" : null}${entry.amount.toLocaleString()} on {entry.date.format("M/D")}</div>
                <div className="muted">
                  <small>
                    {entry.fromAccount?.name || null}
                    {entry.type === "transfer" ? " to " : null}
                    {entry.toAccount?.name || null}
                  </small>
                </div>
                {!entry.hasPosted ? (
                  <div className="muted">
                    <small>
                      Will be posted {moment(today).add(1, "day").isSame(entry.willPostOn) ? "tomorrow" : `${entry.willPostOn.format("ddd, M/D")}`}
                    </small>
                  </div>
                ) : null}
              </td>
              <td className="middle">
                <button
                  onClick={() => {
                    WsServer.sendMessage({
                      method: "updatePostedState",
                      transactionId: entry.transactionId,
                      forDate: entry.forDate.format("YYYY-MM-DD"),
                      hasPosted: !entry.hasPosted
                    })
                  }}
                >
                  {entry.hasPosted ? <>Mark&nbsp;Pending</> : <>Mark&nbsp;Posted</>}
                </button>
              </td>
            </tr>
          ))}
          </tbody>
        ))}
      </table>
    ) : null}

    <div className="flex flex-column mt-8">
      <button onClick={() => onRequestClose()}>View Dashboard</button>
    </div>
  </>)
}

const mapStateToProps = state => ({accounts: state.worksheet.accounts})

export default connect(mapStateToProps)(BalancesAndPendingTransactions)

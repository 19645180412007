import React, { useState, useEffect, useCallback } from "react"
import { connect } from "react-redux"
import { usePlaidLink } from "react-plaid-link"

import Icon from "../components/Icon"
import { getPlaidLinks } from "../store";

const Accounts = ({ plaidLinks }) => {
  const [linkToken, setLinkToken] = useState(null)

  const { open: plaidOpen, ready: plaidReady } = usePlaidLink({
    onSuccess: publicToken => {
      const location = window.location
      fetch(
        `${location.protocol}//${location.hostname}:2053/api/plaid/get-access-token`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ publicToken })
        }
      ).then(() => setLinkToken(null))
    },
    onExit: (err, metadata) => setLinkToken(null),
    token: linkToken,
  })

  const getLinkToken = useCallback(({ accessToken = undefined, products = undefined } = {}) => {
    const location = window.location
    fetch(
      `${location.protocol}//${location.hostname}:2053/api/plaid/create-link-token`,
      {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ accessToken, products })
      },
    )
      .then(res => res.json())
      .then(({ linkToken }) => setLinkToken(linkToken))
  }, [setLinkToken])

  // Open Plaid when link token is set
  useEffect(() => {
    if (plaidOpen && plaidReady && linkToken) {
      plaidOpen()
    }
  }, [plaidOpen, plaidReady, linkToken])

  return (<>
    <div className="p-2">
      <h2 className="block">
        Accounts
        <button className="ml-4" onClick={() => getLinkToken()}>Add Account</button>
        <button className="ml-4" onClick={() => getLinkToken({ products: ['investments'] })}>Add Investment</button>
      </h2>

      {plaidLinks ? (
        <div className="table-overflow">
          <table className="striped">
            <thead>
            <tr>
              <th>Name</th>
            </tr>
            </thead>
            <tbody>
            {Object.entries(plaidLinks).map(([accessToken, accounts]) => (
              <tr key={accessToken} className="pointer">
                <td>
                  {accounts.map(account => (
                    <div key={account.account_id}>
                      {account.official_name || account.name} {account.mask}
                    </div>
                  ))}
                </td>
                <td style={{ width: 0 }}>
                  <div>
                    <button
                      onClick={() => getLinkToken({ accessToken })}
                      className="mb-1"
                    >
                      Relink
                    </button>
                  </div>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="my-16 center">You have no accounts <Icon type="sad" /></div>
      )}
    </div>
  </>)
}

const mapStateToProps = state => ({ plaidLinks: getPlaidLinks(state) })

export default connect(mapStateToProps)(Accounts)

import React, { useState, useEffect, useMemo } from "react"
import { connect } from "react-redux"
import moment from "moment"
import classNames from "classnames"

import Modal from "../components/Modal"
import TransactionForm from "../components/TransactionForm"
import Icon from "../components/Icon"
import { getAccount, getDefaultAccountName } from "../store"

import "./Transactions.scss"

const Transactions = ({getAccount, defaultAccountName, transactions, type}) => {
  const [isAddNewOpen, setIsAddNewOpen] = useState(false)
  const [editTransactionId, setEditTransactionId] = useState(null)
  const [sortKey, setSortKey] = useState(
    localStorage.getItem("sortKey") ? localStorage.getItem("sortKey") : "date"
  )
  const [isSortAscending, setIsSortAscending] = useState(
    localStorage.getItem("isSortAscending") ?
      JSON.parse(localStorage.getItem("isSortAscending")) :
      true
  )

  const today = useMemo(() => moment().startOf("day"), [])

  const transactionsSorted = useMemo(() => (
    transactions
      .filter(transaction => transaction.type === type)
      .map(transaction => {
        let date = moment(transaction.date)
        let endDate = transaction.repeats?.endDate ? moment(transaction.repeats.endDate) : null

        if (transaction.repeats) {
          let adjustment = transaction.adjustments[date.format("YYYY-MM-DD")]
          if (adjustment) date = moment(adjustment.date)

          while (date.isBefore(today) && (!endDate || date.isSameOrBefore(endDate))) {
            date.add(transaction.repeats.interval, transaction.repeats.frequency)

            adjustment = transaction.adjustments[date.format("YYYY-MM-DD")]
            if (adjustment) date = moment(adjustment.date)
          }
        }

        return {
          ...transaction,
          upcomingDate: date.format("YYYY-MM-DD")
        }
      })
      .sort((a, b) => {
        let aValue = a[sortKey]
        let bValue = b[sortKey]

        if (sortKey === "date") {
          aValue = +moment(a.upcomingDate)
          bValue = +moment(b.upcomingDate)
        }
        else if (sortKey === "name") {
          aValue = a[sortKey].toLowerCase()
          bValue = b[sortKey].toLowerCase()
        }

        if (isSortAscending)
          return aValue > bValue ? 1 : -1
        else
          return aValue > bValue ? -1 : 1
      })
  ), [transactions, type, isSortAscending, sortKey, today])

  // Close edit transaction modal if transaction is deleted from backend
  useEffect(() => {
    if (editTransactionId && !transactions.filter(transaction => transaction.id === editTransactionId).length)
      setEditTransactionId(null)
  }, [editTransactionId, transactions])

  // TODO: lower opacity for old dates
  return (<>
    <div className="transactions-container p-2">
      <h2 className="block">
        {type[0].toUpperCase() + type.slice(1)}s
        <button className="ml-4" onClick={() => setIsAddNewOpen(true)}>Add New</button>
      </h2>

      {!!transactionsSorted.length ? (
        <div className="table-overflow">
          <table className="striped">
            <thead>
            <tr>
              <th
                className={classNames("sortable", sortKey === "date" && `sort-${isSortAscending ? "asc" : "desc"}`) }
                onClick={() => {
                  const newIsSortAscending = sortKey === "date" ? !isSortAscending : true

                  setIsSortAscending(newIsSortAscending)
                  setSortKey("date")

                  localStorage.setItem("isSortAscending", JSON.stringify(newIsSortAscending))
                  localStorage.setItem("sortKey", "date")
                }}
              >
                <div>Date</div>
                <div className="muted"><small>Repeats</small></div>
              </th>
              <th
                className={classNames("sortable", sortKey === "name" && `sort-${isSortAscending ? "asc" : "desc"}`) }
                onClick={() => {
                  const newIsSortAscending = sortKey === "name" ? !isSortAscending : true

                  setIsSortAscending(newIsSortAscending)
                  setSortKey("name")

                  localStorage.setItem("isSortAscending", JSON.stringify(newIsSortAscending))
                  localStorage.setItem("sortKey", "name")
                }}
              >
                <div>Name</div>
                <div className="muted"><small>Notes</small></div>
              </th>
              <th
                className={classNames("sortable", sortKey === "amount" && `sort-${isSortAscending ? "asc" : "desc"}`) }
                onClick={() => {
                  const newIsSortAscending = sortKey === "amount" ? !isSortAscending : true

                  setIsSortAscending(newIsSortAscending)
                  setSortKey("amount")

                  localStorage.setItem("isSortAscending", JSON.stringify(newIsSortAscending))
                  localStorage.setItem("sortKey", "amount")
                }}
              >
                <div>Amount</div>
                <div className="muted"><small>Account</small></div>
              </th>
            </tr>
            </thead>
            <tbody>
            {transactionsSorted.map(transaction => {
              const notesLines = transaction.notes ? transaction.notes.split("\n") : []
              const adjustmentsCount = Object.keys(transaction.adjustments).length
              const upcomingDate = moment(transaction.upcomingDate)

              return (
                <tr
                  key={transaction.id}
                  className={classNames("pointer", {muted: upcomingDate.isBefore(today)})}
                  onClick={() => setEditTransactionId(transaction.id)}
                >
                  <td>
                    <div>{upcomingDate.format("M/D/YY")}</div>
                    {transaction.repeats && (<>
                      <div className="repeats muted nowrap">
                        <small>
                          {transaction.repeats.interval === 1 ? (
                            ({days: "daily", weeks: "weekly", months: "monthly", years: "yearly"})[transaction.repeats.frequency]
                          ) : (
                            `${transaction.repeats.interval} ${transaction.repeats.frequency}`
                          )}
                        </small>
                      </div>
                      {transaction.repeats.endDate && (
                        <div className="end-date muted nowrap">
                          <small>{moment(transaction.repeats.endDate).format("M/D/YY")}</small>
                        </div>
                      )}
                      {adjustmentsCount ? (
                        <div className="muted nowrap">
                          <small>
                            {adjustmentsCount}&nbsp;
                            <span className="mobile-only">adjstmnt</span>
                            <span className="desktop-only">adjustment</span>
                            {adjustmentsCount > 1 ? "s" : null}
                          </small>
                        </div>
                      ) : null}
                    </>)}
                  </td>
                  <td>
                    <div>{transaction.name}</div>
                    {notesLines.length ? (
                      <div className="notes muted">
                        <div>
                          <small>{notesLines.shift()}</small>
                        </div>
                        {notesLines.length ? (
                          <div className={notesLines.length > 1 ? "force-ellipsis" : null}>
                            <small>{notesLines.shift()}</small>
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </td>
                  <td>
                    <div>${transaction.amount.toLocaleString()}</div>
                    <div className="muted">
                      <small>
                        {transaction.fromAccountId === "default" ? defaultAccountName : (getAccount(transaction.fromAccountId)?.name || null)}
                        {transaction.type === "transfer" ? " to " : null}
                        {transaction.toAccountId === "default" ? defaultAccountName : (getAccount(transaction.toAccountId)?.name || null)}
                      </small>
                    </div>
                  </td>
                </tr>
              )
            })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="my-16 center">You have no {type}s <Icon type="sad" /></div>
      )}
    </div>

    {isAddNewOpen ? (
      <Modal onRequestClose={() => setIsAddNewOpen(false)}>
        <TransactionForm
          type={type}
          onSubmit={() => setIsAddNewOpen(false)}
          onRequestCancel={() => setIsAddNewOpen(false)}
        />
      </Modal>
    ) : null}

    {editTransactionId && (
      <Modal onRequestClose={() => setEditTransactionId(null)}>
        <TransactionForm
          type={type}
          transactionId={editTransactionId}
          onSubmit={() => setEditTransactionId(null)}
        />
      </Modal>
    )}
  </>)
}

const mapStateToProps = state => ({
  getAccount: accountId => getAccount(state, accountId),
  defaultAccountName: getDefaultAccountName(state),
  transactions: state.worksheet.transactions
})

export default connect(mapStateToProps)(Transactions)
